<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4 col-lg-4">
          <div class="list-grid">
            <div class="list-img">
              <div class="for-rent"><p></p></div>
              <div
                id="carouselExampleControls"
                class="carousel slide"
                data-ride="carousel"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img class="d-block prop__img" />
                  </div>
                </div>
              </div>
              <div class="property-details-holder">
                <div class="card property-details-wrap">
                  <div class="card-body">
                    <div class="details-header">
                      <p class="card-title"></p>
                      <!-- <p @click="moveTo(property.pin)">I'm Intrested</p> -->
                    </div>
                    <div class="location-holder">
                      <img src="../assets/images/map-point.svg" alt="" />
                      <small></small>
                    </div>
                    <p class="details-text"></p>
                    <h2 class="property-amount"></h2>
                    <div class="property-option">
                      <div class="facility-holder">
                        <div class="facility-wrap">
                          <img src="../assets/images/bed.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/bathroom.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/toilet.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/parking.svg" alt="" />
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <div class="list-grid">
            <div class="list-img">
              <div class="for-rent"><p></p></div>
              <div
                id="carouselExampleControls"
                class="carousel slide"
                data-ride="carousel"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img class="d-block prop__img" />
                  </div>
                </div>
              </div>
              <div class="property-details-holder">
                <div class="card property-details-wrap">
                  <div class="card-body">
                    <div class="details-header">
                      <p class="card-title"></p>
                      <!-- <p @click="moveTo(property.pin)">I'm Intrested</p> -->
                    </div>
                    <div class="location-holder">
                      <img src="../assets/images/map-point.svg" alt="" />
                      <small></small>
                    </div>
                    <p class="details-text"></p>
                    <h2 class="property-amount"></h2>
                    <div class="property-option">
                      <div class="facility-holder">
                        <div class="facility-wrap">
                          <img src="../assets/images/bed.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/bathroom.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/toilet.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/parking.svg" alt="" />
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <div class="list-grid">
            <div class="list-img">
              <div class="for-rent"><p></p></div>
              <div
                id="carouselExampleControls"
                class="carousel slide"
                data-ride="carousel"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img class="d-block prop__img" />
                  </div>
                </div>
              </div>
              <div class="property-details-holder">
                <div class="card property-details-wrap">
                  <div class="card-body">
                    <div class="details-header">
                      <p class="card-title"></p>
                      <!-- <p @click="moveTo(property.pin)">I'm Intrested</p> -->
                    </div>
                    <div class="location-holder">
                      <img src="../assets/images/map-point.svg" alt="" />
                      <small></small>
                    </div>
                    <p class="details-text"></p>
                    <h2 class="property-amount"></h2>
                    <div class="property-option">
                      <div class="facility-holder">
                        <div class="facility-wrap">
                          <img src="../assets/images/bed.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/bathroom.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/toilet.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/parking.svg" alt="" />
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <div class="list-grid">
            <div class="list-img">
              <div class="for-rent"><p></p></div>
              <div
                id="carouselExampleControls"
                class="carousel slide"
                data-ride="carousel"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img class="d-block prop__img" />
                  </div>
                </div>
              </div>
              <div class="property-details-holder">
                <div class="card property-details-wrap">
                  <div class="card-body">
                    <div class="details-header">
                      <p class="card-title"></p>
                      <!-- <p @click="moveTo(property.pin)">I'm Intrested</p> -->
                    </div>
                    <div class="location-holder">
                      <img src="../assets/images/map-point.svg" alt="" />
                      <small></small>
                    </div>
                    <p class="details-text"></p>
                    <h2 class="property-amount"></h2>
                    <div class="property-option">
                      <div class="facility-holder">
                        <div class="facility-wrap">
                          <img src="../assets/images/bed.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/bathroom.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/toilet.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/parking.svg" alt="" />
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <div class="list-grid">
            <div class="list-img">
              <div class="for-rent"><p></p></div>
              <div
                id="carouselExampleControls"
                class="carousel slide"
                data-ride="carousel"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img class="d-block prop__img" />
                  </div>
                </div>
              </div>
              <div class="property-details-holder">
                <div class="card property-details-wrap">
                  <div class="card-body">
                    <div class="details-header">
                      <p class="card-title"></p>
                      <!-- <p @click="moveTo(property.pin)">I'm Intrested</p> -->
                    </div>
                    <div class="location-holder">
                      <img src="../assets/images/map-point.svg" alt="" />
                      <small></small>
                    </div>
                    <p class="details-text"></p>
                    <h2 class="property-amount"></h2>
                    <div class="property-option">
                      <div class="facility-holder">
                        <div class="facility-wrap">
                          <img src="../assets/images/bed.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/bathroom.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/toilet.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/parking.svg" alt="" />
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <div class="list-grid">
            <div class="list-img">
              <div class="for-rent"><p></p></div>
              <div
                id="carouselExampleControls"
                class="carousel slide"
                data-ride="carousel"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img class="d-block prop__img" />
                  </div>
                </div>
              </div>
              <div class="property-details-holder">
                <div class="card property-details-wrap">
                  <div class="card-body">
                    <div class="details-header">
                      <p class="card-title"></p>
                      <!-- <p @click="moveTo(property.pin)">I'm Intrested</p> -->
                    </div>
                    <div class="location-holder">
                      <img src="../assets/images/map-point.svg" alt="" />
                      <small></small>
                    </div>
                    <p class="details-text"></p>
                    <h2 class="property-amount"></h2>
                    <div class="property-option">
                      <div class="facility-holder">
                        <div class="facility-wrap">
                          <img src="../assets/images/bed.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/bathroom.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/toilet.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/parking.svg" alt="" />
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <div class="list-grid">
            <div class="list-img">
              <div class="for-rent"><p></p></div>
              <div
                id="carouselExampleControls"
                class="carousel slide"
                data-ride="carousel"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img class="d-block prop__img" />
                  </div>
                </div>
              </div>
              <div class="property-details-holder">
                <div class="card property-details-wrap">
                  <div class="card-body">
                    <div class="details-header">
                      <p class="card-title"></p>
                      <!-- <p @click="moveTo(property.pin)">I'm Intrested</p> -->
                    </div>
                    <div class="location-holder">
                      <img src="../assets/images/map-point.svg" alt="" />
                      <small></small>
                    </div>
                    <p class="details-text"></p>
                    <h2 class="property-amount"></h2>
                    <div class="property-option">
                      <div class="facility-holder">
                        <div class="facility-wrap">
                          <img src="../assets/images/bed.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/bathroom.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/toilet.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/parking.svg" alt="" />
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <div class="list-grid">
            <div class="list-img">
              <div class="for-rent"><p></p></div>
              <div
                id="carouselExampleControls"
                class="carousel slide"
                data-ride="carousel"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img class="d-block prop__img" />
                  </div>
                </div>
              </div>
              <div class="property-details-holder">
                <div class="card property-details-wrap">
                  <div class="card-body">
                    <div class="details-header">
                      <p class="card-title"></p>
                      <!-- <p @click="moveTo(property.pin)">I'm Intrested</p> -->
                    </div>
                    <div class="location-holder">
                      <img src="../assets/images/map-point.svg" alt="" />
                      <small></small>
                    </div>
                    <p class="details-text"></p>
                    <h2 class="property-amount"></h2>
                    <div class="property-option">
                      <div class="facility-holder">
                        <div class="facility-wrap">
                          <img src="../assets/images/bed.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/bathroom.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/toilet.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/parking.svg" alt="" />
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <div class="list-grid">
            <div class="list-img">
              <div class="for-rent"><p></p></div>
              <div
                id="carouselExampleControls"
                class="carousel slide"
                data-ride="carousel"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img class="d-block prop__img" />
                  </div>
                </div>
              </div>
              <div class="property-details-holder">
                <div class="card property-details-wrap">
                  <div class="card-body">
                    <div class="details-header">
                      <p class="card-title"></p>
                      <!-- <p @click="moveTo(property.pin)">I'm Intrested</p> -->
                    </div>
                    <div class="location-holder">
                      <img src="../assets/images/map-point.svg" alt="" />
                      <small></small>
                    </div>
                    <p class="details-text"></p>
                    <h2 class="property-amount"></h2>
                    <div class="property-option">
                      <div class="facility-holder">
                        <div class="facility-wrap">
                          <img src="../assets/images/bed.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/bathroom.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/toilet.svg" alt="" />
                          <p></p>
                        </div>
                        <div class="facility-wrap">
                          <img src="../assets/images/parking.svg" alt="" />
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkeletonLoader",
  components: {
    // Header,
    // Footer
    // AuthNavbar,
  },
  data() {
    return {
      loader: false
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #000000;
$tertiary: #a2b0e3;
$whiteText: #ffffff;

.property-list-holder {
  padding-top: 3rem;
}
.list-header > h3 {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 34px;
  align-items: center;
  color: $secondary;
}

.list-header > p {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: $secondary;
  max-width: 40rem;
  text-align: center;
  margin: auto;
}
.around-you-span {
  color: $primary;
  font-weight: 700;
}
// Property list header

// Property list grid
.property-list {
  padding: 5rem 0 2rem 0;
}

.list-img {
  position: relative;
  width: 100%;
  margin-bottom: 15rem;
}
.for-rent {
  margin: auto;
  top: -4%;
  left: 36%;
  position: absolute;
  width: 26%;
  z-index: 999;
}
.for-rent > p {
  //   background: linear-gradient(180deg, #6886f0 0%, #0033ea 99.48%);
  color: $whiteText;
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  // width: 103px;
  // height: 24px;
  font-size: 12px;
  line-height: 23px;
  height: 30px;
  animation: pulse-bg 1s infinite;
}

.prop__img {
  width: 100% !important;
  height: 266px !important;
  object-fit: cover;
  cursor: not-allowed;
  //   background: #eee;
  height: 13px;
  animation: pulse-bg 1s infinite;
}

.property-details-holder {
  position: absolute;
  top: 92%;
  left: 4%;
  width: 92%;
}
.property-details-wrap {
  background: #ffffff;
  box-shadow: 0px 10px 30px #e2e2e2;
  border-radius: 5px 30px;
}
.details-header {
  // padding-top: 2rem;
  display: flex;
  justify-content: space-between;
}
.details-header > p:first-child {
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $secondary;
  margin-bottom: 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  height: 15px;
  //   background: #eee;
  animation: pulse-bg 1s infinite;
}
.details-header > p:nth-child(2) {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  text-decoration-line: underline;
  color: $primary;
  margin-bottom: 0;
  cursor: pointer;
}

.location-holder {
  display: flex;
  padding: 0.5rem 0 0.6rem 0;
}
.location-holder > img {
  width: 0.7rem;
}
.location-holder > small {
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-align: start;
  padding-left: 0.5rem;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.25);
  //   background-color: #eee;
  width: 100%;
  height: 13px;
  animation: pulse-bg 1s infinite;
}

.details-text {
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 11px;
  line-height: 11px;
  color: $secondary;
  text-align: left;
  padding-top: 0.2rem;
  width: 100%;
  height: 15px;
  animation: pulse-bg 1s infinite;
}

.property-amount {
  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 13px;
  text-align: left;
  color: $secondary;
  padding-top: 0.7rem;
  width: 100%;
  height: 17px;
  animation: pulse-bg 1s infinite;
}
.duration {
  font-size: 16px;
}
.facility-holder {
  display: flex;
  justify-content: space-between;
  padding-top: 0.7rem;
}
// .facility-wrap {
//   // margin: auto;
// }
.facility-wrap > p {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 9px;
  color: $secondary;
  margin-bottom: 0;
  // padding: 0 0.1rem;
  width: 100%;
  height: 11px;
  animation: pulse-bg 1s infinite;
}

.view-more {
  padding-bottom: 5rem;
  //  width: 214px;
  //  height: 61px;
}
.view-more > button {
  background: linear-gradient(180deg, #ffffff 0%, rgba(0, 51, 234, 0.62) 100%),
    linear-gradient(180deg, #0033ea 0%, #0033ea 100%);
  border-radius: 10px;
  border: none;
  color: $whiteText;
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  width: 214px;
  height: 61px;
}

.bedroom__numebr {
  span {
    border: 1px solid #0033ea;
    box-sizing: border-box;
    color: #0033ea;
    padding: 4px 6px;
    // padding: 10px;
  }
}
.span1 {
  padding: 8px 20px;
  background: #0033ea;
  box-sizing: border-box;
  color: $whiteText;
}

@keyframes pulse-bg {
  0% {
    background-color: #eee;
  }
  50% {
    background-color: #eaeaea;
  }
  100% {
    background-color: #eee;
  }
}
// Property list
</style>
